import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Number/Index.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Trading/Form/CancelButton.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = ["data-cy"];
const _hoisted_2 = { class: "text-left cursor-pointer pl-3" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { class: "ml-2" };
const _hoisted_5 = {
  class: "text-white text-xs",
  "data-cy": "open-position-ticker-name-table-data"
};
const _hoisted_6 = { class: "text-left pl-1text-xs" };
const _hoisted_7 = { class: "text-right font-mono text-white text-xs" };
const _hoisted_8 = { key: 0 };
const _hoisted_9 = { class: "text-right font-mono text-white text-xs" };
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { key: 1 };
const _hoisted_12 = {
  key: 0,
  class: "text-gray-500 text-xs"
};
const _hoisted_13 = {
  key: 0,
  class: "text-right font-mono text-white text-xs"
};
const _hoisted_14 = { key: 0 };
const _hoisted_15 = { key: 1 };
const _hoisted_16 = { class: "text-right" };
const _hoisted_17 = {
  key: 0,
  class: "font-mono text-white text-xs"
};
const _hoisted_18 = { class: "flex items-end flex-col" };
const _hoisted_19 = { class: "flex items-center" };
const _hoisted_20 = /* @__PURE__ */ _createElementVNode("span", { class: "mr-1" }, "\u2248", -1);
const _hoisted_21 = { class: "ml-1 text-2xs" };
const _hoisted_22 = { class: "flex mt-1" };
const _hoisted_23 = {
  key: 2,
  class: "text-white text-xs",
  "data-cy": "open-position-no-pnl-table-data"
};
const _hoisted_24 = { class: "text-right font-mono text-white text-xs" };
const _hoisted_25 = { key: 0 };
const _hoisted_26 = { class: "text-2xs text-gray-500" };
const _hoisted_27 = { class: "text-right font-mono text-white text-xs" };
const _hoisted_28 = { key: 0 };
const _hoisted_29 = {
  key: 1,
  class: "flex items-center justify-end"
};
const _hoisted_30 = ["onClick"];
const _hoisted_31 = {
  key: 1,
  class: "text-right font-mono"
};
const _hoisted_32 = {
  key: 0,
  class: "text-white text-xs"
};
const _hoisted_33 = {
  key: 1,
  class: "text-white text-xs"
};
const _hoisted_34 = {
  key: 2,
  class: "flex items-center justify-end text-white text-xs",
  "data-cy": "open-position-leverage-table-data"
};
const _hoisted_35 = /* @__PURE__ */ _createElementVNode("span", { class: "text-gray-300" }, "\xD7", -1);
const _hoisted_36 = {
  key: 3,
  class: "text-gray-500 text-xs",
  "data-cy": "open-position-no-leverage-table-data"
};
const _hoisted_37 = { class: "text-center relative pr-3" };
import { Status } from "@injectivelabs/utils";
import { TradeDirection } from "@injectivelabs/ts-types";
import { HIDDEN_BALANCE_DISPLAY } from "@/app/utils/constants";
import { getMarketRoute } from "@/app/utils/market";
import { BusEvents, Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Row",
  props: {
    hideBalance: Boolean,
    position: {
      required: true,
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const positionStore = usePositionStore();
    const modalStore = useModalStore();
    const route = useRoute();
    const { t } = useLang();
    const { $onError } = useNuxtApp();
    const { error, success } = useNotifications();
    const {
      pnl,
      price,
      market,
      margin,
      quantity,
      markPrice,
      pnlToFormat,
      priceDecimals,
      percentagePnl,
      notionalValue,
      isBinaryOptions,
      quantityDecimals,
      effectiveLeverage,
      markPriceToFormat,
      liquidationPrice
    } = useDerivativePosition(computed(() => props.position));
    const isBinaryOptionsPage = route.name === "binary-options-binaryOption";
    const status = reactive(new Status());
    const reduceOnlyCurrentOrders = computed(
      () => derivativeStore.subaccountOrders.filter(
        (order) => order.isReduceOnly && order.marketId === props.position.marketId
      )
    );
    const hasReduceOnlyOrders = computed(
      () => reduceOnlyCurrentOrders.value.length > 0
    );
    const marketRoute = computed(() => {
      if (!market.value) {
        return void 0;
      }
      return getMarketRoute(market.value);
    });
    function onAddMarginButtonClick() {
      useEventBus(BusEvents.AddMarginToPosition).emit(props.position);
      modalStore.openModal({ type: Modal.AddMarginToPosition });
    }
    function handleClosePosition() {
      if (!market.value) {
        return;
      }
      if (pnl.value.isNaN()) {
        return error({ title: t("trade.no_liquidity") });
      }
      if (hasReduceOnlyOrders.value) {
        return closePositionAndReduceOnlyOrders();
      }
      closePosition();
    }
    function closePosition() {
      if (!market.value) {
        return;
      }
      status.setLoading();
      positionStore.closePosition({
        position: props.position,
        market: market.value
      }).then(() => {
        success({ title: t("trade.position_closed") });
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    function closePositionAndReduceOnlyOrders() {
      if (!market.value) {
        return;
      }
      status.setLoading();
      positionStore.closePositionAndReduceOnlyOrders({
        market: market.value,
        position: props.position,
        reduceOnlyOrders: reduceOnlyCurrentOrders.value
      }).then(() => {
        success({ title: t("trade.position_closed") });
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      const _component_AppNumber = __nuxt_component_2;
      const _component_BaseIcon = __nuxt_component_3;
      const _component_PartialsTradingFormCancelButton = __nuxt_component_4;
      return _unref(market) ? (_openBlock(), _createElementBlock("tr", {
        key: 0,
        "data-cy": "open-position-table-row-" + __props.position.ticker,
        class: "h-[60px]"
      }, [
        _createElementVNode("td", _hoisted_2, [
          _createVNode(_component_NuxtLink, {
            class: "flex items-center justify-start",
            to: _unref(marketRoute)
          }, {
            default: _withCtx(() => [
              _unref(market).baseToken ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_CommonTokenIcon, {
                  token: _unref(market).baseToken
                }, null, 8, ["token"])
              ])) : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(__props.position.ticker), 1)
              ])
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("td", _hoisted_6, [
          _createElementVNode("span", {
            "data-cy": "open-position-trade-direction-table-data",
            class: _normalizeClass(["text-xs", {
              "text-green-500": __props.position.direction === _unref(TradeDirection).Long,
              "text-red-500": __props.position.direction === _unref(TradeDirection).Short
            }])
          }, _toDisplayString(__props.position.direction === _unref(TradeDirection).Long ? _ctx.$t("trade.long") : _ctx.$t("trade.short")), 3)
        ]),
        _createElementVNode("td", _hoisted_7, [
          __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
            key: 1,
            xs: "",
            decimals: _unref(quantityDecimals),
            number: _unref(quantity),
            "data-cy": "open-position-quantity-table-data"
          }, null, 8, ["decimals", "number"]))
        ]),
        _createElementVNode("td", _hoisted_9, [
          __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_AppNumber, {
              xs: "",
              decimals: _unref(priceDecimals),
              number: _unref(price),
              "data-cy": "open-position-price-table-data"
            }, null, 8, ["decimals", "number"]),
            !_unref(markPrice).isNaN() ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(markPriceToFormat)), 1)) : _createCommentVNode("", true)
          ]))
        ]),
        !isBinaryOptionsPage ? (_openBlock(), _createElementBlock("td", _hoisted_13, [
          _unref(isBinaryOptions) ? (_openBlock(), _createElementBlock("span", _hoisted_14, "\u2014")) : __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
            key: 2,
            xs: "",
            decimals: _unref(priceDecimals),
            number: _unref(liquidationPrice),
            "data-cy": "open-position-liquidation-price-table-data"
          }, null, 8, ["decimals", "number"]))
        ])) : _createCommentVNode("", true),
        _createElementVNode("td", _hoisted_16, [
          __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : !_unref(pnl).isNaN() ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["flex items-center justify-end font-medium text-xs font-mono", { "text-green-500": _unref(pnl).gte(0), "text-red-500": _unref(pnl).lt(0) }])
          }, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("span", _hoisted_19, [
                _hoisted_20,
                _createElementVNode("span", null, _toDisplayString(_unref(pnl).gte(0) ? "+" : ""), 1),
                _createElementVNode("span", {
                  "data-cy": "postion-entry-pnl",
                  class: _normalizeClass({
                    "text-green-500": _unref(pnl).gte(0),
                    "text-red-500": _unref(pnl).lt(0)
                  })
                }, _toDisplayString(_unref(pnlToFormat)), 3),
                _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(market).quoteToken.symbol), 1)
              ]),
              _createElementVNode("span", _hoisted_22, _toDisplayString((_unref(percentagePnl).gte(0) ? "+" : "") + _unref(percentagePnl).toFormat(2)) + "% ", 1)
            ])
          ], 2)) : (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.$t("trade.not_available_n_a")), 1))
        ]),
        _createElementVNode("td", _hoisted_24, [
          __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
            key: 1,
            xs: "",
            decimals: _unref(priceDecimals),
            number: _unref(notionalValue),
            "data-cy": "open-position-total-table-data"
          }, {
            addon: _withCtx(() => [
              _createElementVNode("span", _hoisted_26, _toDisplayString(_unref(market).quoteToken.symbol), 1)
            ]),
            _: 1
          }, 8, ["decimals", "number"]))
        ]),
        _createElementVNode("td", _hoisted_27, [
          __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_29, [
            _createVNode(_component_AppNumber, {
              xs: "",
              "data-cy": "open-position-margin-table-data",
              decimals: _unref(priceDecimals),
              number: _unref(margin)
            }, null, 8, ["decimals", "number"]),
            !_unref(isBinaryOptions) ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "cursor-pointer flex items-center justify-center rounded-full bg-opacity-10 hover:bg-opacity-30 hover:text-blue-500 text-gray-500 min-w-4 w-4 h-4 border border-gray-500 hover:border-blue-500 ml-1",
              onClick: _withModifiers(onAddMarginButtonClick, ["stop"])
            }, [
              _createVNode(_component_BaseIcon, {
                name: "plus",
                xs: ""
              })
            ], 8, _hoisted_30)) : _createCommentVNode("", true)
          ]))
        ]),
        !isBinaryOptionsPage ? (_openBlock(), _createElementBlock("td", _hoisted_31, [
          _unref(isBinaryOptions) ? (_openBlock(), _createElementBlock("span", _hoisted_32, "\u2014")) : __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : _unref(effectiveLeverage).gte(0) ? (_openBlock(), _createElementBlock("span", _hoisted_34, [
            _createTextVNode(_toDisplayString(_unref(effectiveLeverage).toFormat(2)) + " ", 1),
            _hoisted_35
          ])) : (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(_ctx.$t("trade.not_available_n_a")), 1))
        ])) : _createCommentVNode("", true),
        _createElementVNode("td", _hoisted_37, [
          !__props.hideBalance ? (_openBlock(), _createBlock(_component_PartialsTradingFormCancelButton, {
            key: 0,
            status: _unref(status),
            "data-cy": "open-position-cancel-link",
            onClick: handleClosePosition
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_BaseIcon, {
                name: "close",
                sm: ""
              })
            ]),
            _: 1
          }, 8, ["status"])) : _createCommentVNode("", true)
        ])
      ], 8, _hoisted_1)) : _createCommentVNode("", true);
    };
  }
});
