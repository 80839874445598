import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "h-8 text-left pl-3" };
const _hoisted_2 = { class: "flex items-center" };
const _hoisted_3 = { class: "flex items-center justify-end" };
const _hoisted_4 = { class: "h-8 text-right" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "flex items-center justify-end" };
const _hoisted_7 = { class: "flex items-center justify-end" };
const _hoisted_8 = { class: "flex items-center justify-end" };
const _hoisted_9 = { class: "h-8 text-right" };
const _hoisted_10 = {
  key: 1,
  class: "text-right"
};
const _hoisted_11 = /* @__PURE__ */ _createElementVNode("th", null, null, -1);
export default /* @__PURE__ */ _defineComponent({
  __name: "Header",
  setup(__props) {
    const route = useRoute();
    const isBinaryOptionsPage = route.name === "binary-options-binaryOption";
    return (_ctx, _cache) => {
      const _component_CommonInfoTooltip = __nuxt_component_0;
      return _openBlock(), _createElementBlock("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.pair")), 1)
          ]),
          _createElementVNode("th", null, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("trade.side")), 1)
          ]),
          _createElementVNode("th", null, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.amount")), 1),
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _ctx.$t("trade.amount_tooltip")
              }, null, 8, ["tooltip"])
            ])
          ]),
          _createElementVNode("th", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.entryMark")), 1)
          ]),
          !isBinaryOptionsPage ? (_openBlock(), _createElementBlock("th", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.estLiqPrice")), 1),
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _ctx.$t("trade.liquidation_price_tooltip")
              }, null, 8, ["tooltip"])
            ])
          ])) : _createCommentVNode("", true),
          _createElementVNode("th", null, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.unrealized_pnl")), 1),
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _ctx.$t("trade.unrealized_pnl_tooltip")
              }, null, 8, ["tooltip"])
            ])
          ]),
          _createElementVNode("th", null, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.total")), 1),
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _ctx.$t("trade.total_tooltip")
              }, null, 8, ["tooltip"])
            ])
          ]),
          _createElementVNode("th", _hoisted_9, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.margin")), 1)
          ]),
          !isBinaryOptionsPage ? (_openBlock(), _createElementBlock("th", _hoisted_10, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.leverage")), 1)
          ])) : _createCommentVNode("", true),
          _hoisted_11
        ])
      ]);
    };
  }
});
