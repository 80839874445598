import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Number/Index.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Common/Table/Row.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between col-span-2 text-xs leading-5 pb-1" };
const _hoisted_2 = { class: "flex items-center gap-1" };
const _hoisted_3 = {
  key: 0,
  class: "w-4 h-4"
};
const _hoisted_4 = { class: "text-gray-200 font-semibold" };
const _hoisted_5 = {
  key: 1,
  class: "font-mono"
};
const _hoisted_6 = { class: "flex items-center justify-center rounded-full bg-opacity-10 w-5 h-5 hover:bg-opacity-10 bg-red-500 text-red-500" };
const _hoisted_7 = { class: "text-gray-500 uppercase tracking-widest text-3xs" };
const _hoisted_8 = { class: "text-right" };
const _hoisted_9 = { key: 0 };
const _hoisted_10 = { class: "text-gray-500 uppercase tracking-widest text-3xs" };
const _hoisted_11 = { class: "text-right" };
const _hoisted_12 = { key: 0 };
const _hoisted_13 = {
  key: 1,
  class: "flex justify-end items-center whitespace-nowrap"
};
const _hoisted_14 = { class: "text-gray-500 ml-1" };
const _hoisted_15 = { class: "text-gray-500 uppercase tracking-widest text-3xs" };
const _hoisted_16 = { class: "text-right" };
const _hoisted_17 = { key: 0 };
const _hoisted_18 = {
  key: 1,
  class: "flex justify-end items-center"
};
const _hoisted_19 = { key: 0 };
const _hoisted_20 = { class: "text-gray-500 uppercase tracking-widest text-3xs" };
const _hoisted_21 = { class: "text-right" };
const _hoisted_22 = { key: 0 };
const _hoisted_23 = {
  key: 1,
  class: "flex justify-end items-center"
};
const _hoisted_24 = { key: 0 };
const _hoisted_25 = /* @__PURE__ */ _createElementVNode("span", null, "\u2248", -1);
const _hoisted_26 = { class: "text-3xs" };
const _hoisted_27 = { class: "text-gray-500 uppercase tracking-widest text-3xs" };
const _hoisted_28 = { class: "text-right" };
const _hoisted_29 = { key: 0 };
const _hoisted_30 = { class: "text-gray-500 text-3xs" };
const _hoisted_31 = { class: "text-gray-500 uppercase tracking-widest text-3xs" };
const _hoisted_32 = { class: "text-right" };
const _hoisted_33 = { key: 0 };
const _hoisted_34 = {
  key: 1,
  class: "flex items-center justify-end h-4"
};
const _hoisted_35 = ["onClick"];
import { Status } from "@injectivelabs/utils";
import { TradeDirection } from "@injectivelabs/ts-types";
import { HIDDEN_BALANCE_DISPLAY } from "@/app/utils/constants";
import { BusEvents, Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Mobile",
  props: {
    hideBalance: Boolean,
    position: {
      required: true,
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const positionStore = usePositionStore();
    const modalStore = useModalStore();
    const { t } = useLang();
    const { error, success } = useNotifications();
    const { $onError } = useNuxtApp();
    const {
      pnl,
      price,
      market,
      margin,
      quantity,
      pnlToFormat,
      priceDecimals,
      notionalValue,
      quantityDecimals,
      effectiveLeverage,
      markPriceToFormat,
      liquidationPrice
    } = useDerivativePosition(computed(() => props.position));
    const status = reactive(new Status());
    const reduceOnlyCurrentOrders = computed(
      () => derivativeStore.subaccountOrders.filter(
        (order) => order.isReduceOnly && order.marketId === props.position.marketId
      )
    );
    const hasReduceOnlyOrders = computed(
      () => reduceOnlyCurrentOrders.value.length > 0
    );
    function handleAddMargin() {
      useEventBus(BusEvents.AddMarginToPosition).emit(props.position);
      modalStore.openModal({ type: Modal.AddMarginToPosition });
    }
    function handleClosePositionClick() {
      if (!market.value) {
        return;
      }
      if (pnl.value.isNaN()) {
        return error({ title: t("trade.no_liquidity") });
      }
      handleClosePosition();
    }
    function handleClosePosition() {
      if (hasReduceOnlyOrders.value) {
        return closePositionAndReduceOnlyOrders();
      }
      return closePosition();
    }
    function closePosition() {
      if (!market.value) {
        return;
      }
      status.setLoading();
      positionStore.closePosition({
        position: props.position,
        market: market.value
      }).then(() => {
        success({ title: t("trade.position_closed") });
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    function closePositionAndReduceOnlyOrders() {
      if (!market.value) {
        return;
      }
      status.setLoading();
      positionStore.closePositionAndReduceOnlyOrders({
        market: market.value,
        position: props.position,
        reduceOnlyOrders: reduceOnlyCurrentOrders.value
      }).then(() => {
        success({ title: t("trade.position_closed") });
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_BaseIcon = __nuxt_component_1;
      const _component_AppButton = __nuxt_component_2;
      const _component_AppNumber = __nuxt_component_3;
      const _component_CommonTableRow = __nuxt_component_4;
      return _unref(market) ? (_openBlock(), _createBlock(_component_CommonTableRow, {
        key: 0,
        dense: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", {
                class: _normalizeClass({
                  "text-green-500": __props.position.direction === _unref(TradeDirection).Long,
                  "text-red-500": __props.position.direction === _unref(TradeDirection).Short
                })
              }, _toDisplayString(__props.position.direction === _unref(TradeDirection).Long ? _ctx.$t("trade.long") : _ctx.$t("trade.short")), 3),
              _unref(market).baseToken ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _unref(market).baseToken ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
                  key: 0,
                  token: _unref(market).baseToken,
                  sm: ""
                }, null, 8, ["token"])) : _createCommentVNode("", true)
              ])) : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_4, _toDisplayString(__props.position.ticker), 1),
              !__props.hideBalance && _unref(effectiveLeverage).gte(0) ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(_unref(effectiveLeverage).toFormat(2)) + "x", 1)
              ])) : _createCommentVNode("", true)
            ]),
            !__props.hideBalance ? (_openBlock(), _createBlock(_component_AppButton, {
              key: 0,
              class: "cursor-pointer rounded",
              status: _unref(status),
              onClick: handleClosePositionClick
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_BaseIcon, {
                    name: "close",
                    class: "h-3 w-3"
                  })
                ])
              ]),
              _: 1
            }, 8, ["status"])) : _createCommentVNode("", true)
          ]),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("trade.amount")), 1),
          _createElementVNode("div", _hoisted_8, [
            __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
              key: 1,
              dense: "",
              decimals: _unref(quantityDecimals),
              number: _unref(quantity)
            }, null, 8, ["decimals", "number"]))
          ]),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("trade.entryMark")), 1),
          _createElementVNode("div", _hoisted_11, [
            __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createVNode(_component_AppNumber, {
                dense: "",
                decimals: _unref(priceDecimals),
                number: _unref(price)
              }, null, 8, ["decimals", "number"]),
              _createElementVNode("span", _hoisted_14, "/ " + _toDisplayString(_unref(markPriceToFormat)), 1)
            ]))
          ]),
          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("trade.estLiqPrice")), 1),
          _createElementVNode("div", _hoisted_16, [
            __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_18, [
              __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
                key: 1,
                decimals: _unref(priceDecimals),
                number: _unref(liquidationPrice)
              }, null, 8, ["decimals", "number"]))
            ]))
          ]),
          _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t("trade.unrealized_pnl")), 1),
          _createElementVNode("div", _hoisted_21, [
            __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_23, [
              _unref(pnl).isNaN() ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.$t("trade.not_available_n_a")), 1)) : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([{
                  "text-green-500": _unref(pnl).gte(0) && !_unref(pnl).isNaN(),
                  "text-red-500": _unref(pnl).lt(0) && !_unref(pnl).isNaN()
                }, "flex items-center gap-1"])
              }, [
                _hoisted_25,
                _createElementVNode("span", null, _toDisplayString(_unref(pnl).gte(0) ? "+" : ""), 1),
                _createElementVNode("span", null, _toDisplayString(_unref(pnlToFormat)), 1),
                _createElementVNode("span", _hoisted_26, _toDisplayString(_unref(market).quoteToken.symbol), 1)
              ], 2))
            ]))
          ]),
          _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t("trade.total")), 1),
          _createElementVNode("div", _hoisted_28, [
            __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
              key: 1,
              decimals: _unref(priceDecimals),
              number: _unref(notionalValue)
            }, {
              addon: _withCtx(() => [
                _createElementVNode("span", _hoisted_30, _toDisplayString(_unref(market).quoteToken.symbol), 1)
              ]),
              _: 1
            }, 8, ["decimals", "number"]))
          ]),
          _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t("trade.margin")), 1),
          _createElementVNode("div", _hoisted_32, [
            __props.hideBalance ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_unref(HIDDEN_BALANCE_DISPLAY)), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_34, [
              _createVNode(_component_AppNumber, {
                decimals: _unref(priceDecimals),
                number: _unref(margin)
              }, null, 8, ["decimals", "number"]),
              _createElementVNode("button", {
                role: "button",
                type: "button",
                class: "border border-gray-500 text-gray-500 ml-2 px-1",
                onClick: _withModifiers(handleAddMargin, ["stop", "prevent"])
              }, " + ", 8, _hoisted_35)
            ]))
          ])
        ]),
        _: 1
      })) : _createCommentVNode("", true);
    };
  }
});
